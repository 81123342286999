<template>
  <section id="crm-user-list">
    <b-breadcrumb
      v-if="showFilters"
      :items="breadcrumb"
    />

    <b-card-actions
      v-if="showFilters"
      ref="filters"
      title="Filtros"
      :title-collapse="true"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      :collapsed="false"
      @refresh="refreshStop('cardAction')"
    >
      <user-list-filters
        :filters="filtersValues"
        :is-loading="isLoading"
        @search="listClients"
      />
    </b-card-actions>

    <b-card-actions
      ref="clients"
      :title="pageTitle"
      :title-collapse="true"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="true"
      :collapsed="collapsed"
      @refresh="listClients"
    >
      <b-row v-if="showInternalFilters">
        <b-col>
          <user-list-filters
            :filters="filtersValues"
            :is-loading="isLoading"
            @search="listClients"
          />

          <hr />
        </b-col>
      </b-row>

      <b-row
        class="header-card"
        align-v="center"
      >
        <b-col>
          <div
            class="text-right d-flex justify-content-end mb-2"
          >
            <b-button
              class="ml-2"
              title="Criar um novo cliente"
              @click="newClient"
            >
              Novo Cliente
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            :busy="isLoading || isRemoving"
            fixed
            :items="clients"
            :fields="resultFields"
            sort-icon-left
            no-local-sorting
          >
            <template #head(name)>
              Nome
            </template>

            <template #head(email)>
              E-mail
            </template>

            <template #head(doc)>
              CPF/CNPJ
            </template>

            <template #head(permission_type)>
              Permissão
            </template>

            <template #head(actions)>
              <div class="text-nowrap text-center">
                Ações
              </div>
            </template>

            <template #cell(doc)="row">
              <span v-if="row.item.person_type === 'pf'">{{ row.item.cpf | cpf }}</span>
              <span v-else>{{ row.item.cnpj | cnpj }}</span>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  :disabled="isLoading || isRemoving"
                  title="Editar"
                  @click="editClient(row.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  :disabled="isLoading || isRemoving"
                  title="Remover"
                  @click="removeClient(row.item)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page"
            :link-gen="() => { return '#' }"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal
      ref="modal-client-edit"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      userIdToEdit: {{ userIdToEdit }}<br />
      accountId: {{ accountId }}

      <client-edit
        :client-id="userIdToEdit"
        :account-id="accountId"
        @close="$refs['modal-client-edit'].hide()"
        @saved="saved"
      />
    </b-modal>

  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BPaginationNav,
  BRow,
  BTable,
} from 'bootstrap-vue'
import CrmService from '@/services/crmService'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ClientEdit from '@/views/crm/client/ClientEdit.vue'
import UserListFilters from '@/views/crm/user/UserListFilters.vue'
import { crmAccountUserPermissionTypes } from '@/utils/options'

export default {
  components: {
    ClientEdit,
    BCardActions,
    BPaginationNav,
    BButton,
    BTable,
    BRow,
    BCol,
    BBreadcrumb,
    UserListFilters,
  },

  mixins: [toasts, swall],

  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    editInModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    showInternalFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    accountId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Usuário',
          to: { name: 'crm-user-list' },
        },
      ],
      filtersValues: {
        name: '',
        email: '',
        doc: '',
        page: 1,
      },
      userIdToEdit: null,
      pageTitle: '',
      pages: {},
      currentPage: 1,
      clients: [],
      accountUser: {
        user_id: null,
        account_id: '',
        email: '',
        permission_type: '',
      },
      resultFields: [
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'doc', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isRemoving: false,
      isSubmiting: false,
      crmAccountUserPermissionTypes,
      types: {
        user: 'user',
        client: 'client',
      },
    }
  },

  watch: {
    currentPage(page) {
      this.currentPage = page
      this.listClients()
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.pageTitle = this.title ?? 'Usuários'

    this.listClients()
  },

  methods: {
    saved() {
      this.$refs['modal-client-edit'].hide()
      this.listClients()
    },

    reset() {
      Object.entries(this.filtersValues).forEach(item => {
        this.filtersValues[item[0]] = ''
      })
      this.listClients()
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.listClients()
    },

    newClient() {
      this.userIdToEdit = 0
      this.$refs['modal-client-edit'].show()
    },

    editClient(item) {
      this.userIdToEdit = item.id

      if (this.editInModal) {
        this.$refs['modal-client-edit'].show()
      } else {
        this.$router.push({ name: 'crm-client-edit', params: { userId: this.userIdToEdit } })
      }
    },

    removeClient(item) {
      this.$swal({
        title: 'Remover cliente?',
        text: `Deseja remover o cliente '${item.name}'?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.removeClient(item.id).then(response => {
            if (response.data.deleted) {
              this.listClients()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    listClients() {
      if (this.accountId !== 0) {
        this.isLoading = true

        // @todo deixar somente o client
        if (this.accountId && this.type === this.types.user) {
          this.filtersValues.account_id = this.accountId
          delete this.filtersValues.client_account_id
        } else if (this.accountId && this.type === this.types.client) {
          this.filtersValues.client_account_id = this.accountId
          delete this.filtersValues.account_id
        }

        this.filtersValues.page = this.currentPage
        CrmService.listClients(this.filtersValues).then(response => {
          if (response.data !== null) {
            this.clients = response.data.clients.data
            this.pages = {
              ...response.data.clients,
              data: null,
            }
          }
        }).finally(() => {
          this.isLoading = false
          this.$refs.clients.showLoading = false
        })
      }
    },
  },
}
</script>
