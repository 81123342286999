export const productsType = {
  simple: 'simple',
  variation: 'variation',
}

export const userPersonType = {
  pf: 'pf',
  pj: 'pj',
}

export const productsUnit = {
  unit: 'unit',
  lot: 'lot',
  pack: 'pack',
  box: 'box',
  pallet: 'pallet',
  meter: 'meter',
  square_meter: 'square_meter',
}

export const negotiationStatus = {
  lead: 'lead',
  proposal: 'proposal',
  order: 'order',
  closed: 'closed',
  lost: 'lost',
}
