<template>
  <section id="crm-user-list">
    <b-row>

      <b-col cols="3">
        <b-form-group
          label="Negócio"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="filters.name"
            @keyup.enter="searchAndResetPage"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          label="Produto"
          label-for="product_name"
        >
          <b-form-input
            id="product_name"
            v-model="filters.product_name"
            @keyup.enter="searchAndResetPage"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-form-select
            id="status"
            v-model="filters.status"
            :options="crmNegotiationStatus"
            @keyup.enter="searchAndResetPage"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="3"
        class="form-buttons"
      >
        <b-form-group
          label=""
          label-for="btn-buscar"
          style="margin-top: 20px"
        >
          <b-button
            id="btn-buscar"
            variant="primary"
            @click="searchAndResetPage"
          >
            Buscar
          </b-button>

          <b-button
            id="btn-limpar"
            variant="outline-secondary"
            @click="reset()"
          >
            Limpar
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import { crmNegotiationStatus } from '@/utils/options'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BRow,
    BCol,
  },

  mixins: [toasts, swall],

  props: {
    filters: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
    }
  },

  computed: {
    crmNegotiationStatus() {
      return [
        {
          text: 'Todos',
          value: '',
        },
        ...crmNegotiationStatus,
      ]
    },
  },

  watch: {
  },

  created() {
  },

  methods: {
    reset() {
      Object.entries(this.filters).forEach(item => {
        this.filters[item[0]] = ''
      })
      this.$emit('search')
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.$emit('search')
    },
  },
}
</script>
