<template>
  <section id="crm-user-list">
    <b-breadcrumb
      v-if="showFilters"
      :items="breadcrumb"
    />

    <b-card-actions
      v-if="showFilters"
      ref="filters"
      :title-collapse="true"
      title="Filtros"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      :collapsed="false"
      @refresh="listProducts"
    >
      <product-list-filters
        :filters.sync="filtersValues"
        :is-loading="isLoading"
        @search="listProducts"
      />
    </b-card-actions>

    <b-card-actions
      ref="products"
      :title="pageTitle"
      :title-collapse="true"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="true"
      :collapsed="collapsed"
      @refresh="listProducts"
    >
      <b-row>
        <b-col>
          <product-list-filters
            :filters.sync="filtersValues"
            :is-loading="isLoading"
            @search="listProducts"
          />

          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :busy="isLoading || isRemoving"
            :items="products"
            :fields="resultFields"
            striped
            sort-icon-left
            no-local-sorting
          >
            <template #head(name)>
              Nome
            </template>

            <template #head(price)>
              Preço
            </template>

            <template #head(unit_quantity)>
              Quantidade
            </template>

            <template #head(type)>
              Tipo
            </template>

            <template #head(actions)>
              Ações
            </template>

            <template #cell(type)="row">
              <span>{{ $t(`crm.product.type.${row.item.type}`) }}</span>
            </template>

            <template #cell(price)="row">
              <span v-if="row.item.type === productsType.simple">{{ row.item.price | currency }}</span>
              <span v-if="row.item.type === productsType.variation"></span>

              <product-modal-prices
                v-if="row.item.c_prices && row.item.c_prices.length"
                :product="row.item"
              />
            </template>

            <template #cell(unit_quantity)="row">
              <span v-if="row.item.type === productsType.simple">{{ `${row.item.unit_quantity} (${$t(`crm.product.unit_abbreviated.${row.item.unit}`)})` }}</span>
              <span v-if="row.item.type === productsType.variation">{{ $t(`crm.product.unit_abbreviated.${row.item.unit}`) }}</span>
            </template>

            <template #row-details="row">
              <div id="div-variations">
                <b-table
                  :items="row.item.variations"
                  :fields="variationFields"
                  :striped="false"
                  fixed
                  small
                  sort-icon-left
                  no-local-sorting
                >
                  <template #head(price)>
                    Preço
                  </template>

                  <template #head(unit_quantity)>
                    Quantidade
                  </template>

                  <template #head(variation_type)>
                    Tipo da Variação
                  </template>

                  <template #head(variation_value)>
                    Variação
                  </template>

                  <template #cell(price)="row2">
                    <span>{{ row2.item.price | currency }}</span>
                  </template>
                </b-table>
              </div>
            </template>

            <template #cell(actions)>
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  :disabled="isLoading || isRemoving"
                  title="Editar"
                  @click="toastSuccess('Edição ainda não foi feita')"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  title="Remover"
                  :disabled="isLoading || isRemoving"
                  @click="toastSuccess('Remoção ainda não foi feita')"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page"
            :link-gen="() => { return '#' }"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card-actions>

  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BPaginationNav,
  BRow,
  BTable,
} from 'bootstrap-vue'
import CrmService from '@/services/crmService'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { crmAccountUserPermissionTypes } from '@/utils/options'
import ProductListFilters from '@/views/crm/product/ProductListFilters.vue'
import { productsType } from '@/utils/crmConstants'
import ProductModalPrices from '@/views/crm/product/ProductModalPrices.vue'

export default {
  components: {
    ProductModalPrices,
    ProductListFilters,
    BCardActions,
    BPaginationNav,
    BButton,
    BTable,
    BRow,
    BCol,
    BBreadcrumb,
  },

  mixins: [toasts, swall],

  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    editInModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    accountId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Usuário',
          to: { name: 'crm-user-list' },
        },
      ],
      filtersValues: {
        account_id: null,
        name: '',
        type: '',
        page: 1,
      },
      userIdToEdit: null,
      pageTitle: '',
      pages: {},
      currentPage: 1,
      products: [],
      newAccountUser: {
        account_id: '',
        email: '',
        permission_type: '',
      },
      variationFields: [
        { key: 'variation_type', sortable: false },
        { key: 'variation_value', sortable: false },
        { key: 'sku', sortable: false },
        { key: 'price', sortable: false },
        { key: 'unit_quantity', sortable: false },
      ],
      resultFields: [
        { key: 'name', sortable: false },
        { key: 'type', sortable: false },
        { key: 'sku', sortable: false },
        { key: 'price', sortable: false },
        { key: 'unit_quantity', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isRemoving: false,
      isSubmiting: false,
      crmAccountUserPermissionTypes,
      productsType,
    }
  },

  computed: {
    entityName() {
      return this.type === 'client' ? 'cliente' : 'usuário'
    },
  },

  watch: {
    currentPage(page) {
      this.currentPage = page
      this.listProducts()
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.pageTitle = this.title ?? 'Produtos'

    this.listProducts()
  },

  methods: {
    reset() {
      Object.entries(this.filtersValues).forEach(item => {
        this.filtersValues[item[0]] = ''
      })
      this.listProducts()
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.listProducts()
    },

    listProducts() {
      if (this.accountId !== 0) {
        this.isLoading = true

        if (this.accountId) {
          this.filtersValues.account_id = this.accountId
        }

        this.filtersValues.page = this.currentPage
        CrmService.listProducts(this.filtersValues).then(response => {
          if (response.data !== null) {
            this.products = response.data.products.data.map(m => ({
              ...m,
              _showDetails: m.type === this.productsType.variation,
            }))
            this.pages = {
              ...response.data.products,
              data: null,
            }
          }
        }).finally(() => {
          this.isLoading = false
          this.$refs.products.showLoading = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
  #div-variations{
    padding-right: 160px;

    tbody tr,
    tbody tr td {
      background-color: transparent;

    }
    thead tr,
    thead tr th {
      background-color: transparent;
    }
  }
</style>
