<template>
  <section id="crm-user-list">
    <b-breadcrumb
      v-if="showFilters"
      :items="breadcrumb"
    />

    <b-card-actions
      ref="teams"
      :title="pageTitle"
      :title-collapse="true"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="true"
      :collapsed="collapsed"
      @refresh="listTeams"
    >
      <b-row
        class="header-card"
        align-v="center"
      >
        <b-col>
          <div
            class="text-right d-flex justify-content-end mb-2"
          >
            <b-button
              class="ml-2"
              title="Criar um novo time"
              @click="newTeamOpen"
            >
              Novo Time
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            :busy="isLoading || isRemoving"
            :items="teams"
            :fields="resultFields"
            striped
            sort-icon-left
            no-local-sorting
          >
            <template #head(name)>
              Nome
            </template>

            <template #head(users)>
              Usuários
            </template>

            <template #head(actions)>
              <div class="text-center">
                <span>Ações</span>
              </div>
            </template>

            <template #cell(users)="row">
              <b-table
                :items="row.item.users"
                :fields="usersFields"
                :striped="false"
                fixed
                small
                sort-icon-left
                no-local-sorting
              >
                <template #head(name)>
                  Nome
                </template>

                <template #head(email)>
                  E-mail
                </template>

                <template #head(actions)>
                  <div class="text-center">
                    <span>Ações</span>
                  </div>
                </template>

                <template #cell(actions)="rowUser">
                  <div class="text-nowrap text-center">
                    <b-button
                      variant="flat-primary"
                      :disabled="isLoading || isRemoving"
                      title="Remover usuário do time"
                      @click="teamUserRemove(row.item, rowUser.item)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </template>

            <template #cell(actions)="row">
              <div class="text-center d-flex flex-column">
                <b-button
                  variant="flat-primary"
                  :disabled="isLoading || isRemoving"
                  title="Alterar nome do time"
                  @click="editTeam(row.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  title="Remover time"
                  :disabled="isLoading || isRemoving"
                  @click="removeTeam(row.item)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  title="Adicionar usuário"
                  :disabled="isLoading || isRemoving"
                  @click="addTeamUser(row.item)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page"
            :link-gen="() => { return '#' }"
            align="end"
          />
        </b-col>
      </b-row>

      <b-modal
        ref="modal-new-team"
        no-close-on-esc
        no-close-on-backdrop
        size="sm"
        :ok-disabled="isSubmiting"
        :cancel-disabled="isSubmiting"
        :ok-title="team.id ? 'Alterar' : 'Criar'"
        @ok.prevent="newTeamSave"
      >
        <validation-observer ref="rulesTeam">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Nome"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="team.name"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>

      <b-modal
        ref="modal-add-team-user"
        no-close-on-esc
        no-close-on-backdrop
        size="sm"
        ok-title="Adicionar"
        :ok-disabled="isSubmiting"
        :cancel-disabled="isSubmiting"
        @ok.prevent="teamUserSave"
      >
        <validation-observer ref="rulesTeamUser">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Nome"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="accountUserSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accountUsers"
                    label="title"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
    </b-card-actions>

  </section>
</template>

<script>
import {
  BModal,
  BBreadcrumb,
  BButton,
  BCol,
  BPaginationNav,
  BRow,
  BTable, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import CrmService from '@/services/crmService'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { crmAccountUserPermissionTypes } from '@/utils/options'
import { productsType } from '@/utils/crmConstants'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    BPaginationNav,
    BButton,
    BTable,
    BRow,
    BCol,
    BModal,
    BBreadcrumb,
    vSelect,
  },

  mixins: [toasts, swall],

  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    accountId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Usuário',
          to: { name: 'crm-user-list' },
        },
      ],
      filtersValues: {
        account_id: null,
        name: '',
        page: 1,
      },
      teams: [],
      pageTitle: '',
      pages: {},
      currentPage: 1,
      team: {
        id: null,
        account_id: '',
        name: '',
      },
      teamUser: {
        account_id: '',
        user_id: '',
      },
      usersFields: [
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 120px' },
      ],
      resultFields: [
        { key: 'name', sortable: false },
        { key: 'users', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 120px' },
      ],
      accountUsers: [],
      accountUserSelected: null,
      userData: null,
      isLoading: false,
      isRemoving: false,
      isSubmiting: false,
      crmAccountUserPermissionTypes,
      productsType,
    }
  },

  computed: {
    entityName() {
      return this.type === 'client' ? 'cliente' : 'usuário'
    },
  },

  watch: {
    currentPage(page) {
      this.currentPage = page
      this.listTeams()
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.pageTitle = this.title ?? 'Times'

    this.listUsers()
    this.listTeams()
  },

  methods: {
    editTeam(team) {
      this.team = team
      this.$refs['modal-new-team'].show()
    },

    removeTeam(team) {
      this.$swal({
        title: 'Remover time?',
        text: `Deseja remover o time '${team.name}'?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.removeTeam(team.id).then(response => {
            if (response.data.deleted) {
              this.listTeams()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    newTeamOpen() {
      this.team.id = null
      this.$refs['modal-new-team'].show()
    },

    newTeamCreate() {
      this.isSubmiting = true

      this.team.account_id = this.accountId
      CrmService.postTeam(this.team).then(response => {
        if (response.data.team) {
          this.toastSuccess('Sucesso!', 'Time criado!')
          this.listTeams()
          this.$refs['modal-new-team'].hide()
        } else {
          this.swallError('Erro!', 'Não foi possível criar o time', 'Fechar')
        }
      }).catch(error => {
        this.swallError('Erro!', error.response.data.message, 'Fechar')
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    newTeamEdit() {
      this.isSubmiting = true

      this.team.account_id = this.accountId
      CrmService.putTeam(this.team).then(response => {
        if (response.data.team) {
          this.toastSuccess('Sucesso!', 'Time alterado!')
          this.listTeams()
          this.$refs['modal-new-team'].hide()
        } else {
          this.swallError('Erro!', 'Não foi possível alterar o time', 'Fechar')
        }
      }).catch(error => {
        this.swallError('Erro!', error.response.data.message, 'Fechar')
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    newTeamSave() {
      this.$refs.rulesTeam.validate().then(success => {
        if (success) {
          if (this.team.id) {
            this.newTeamEdit()
          } else {
            this.newTeamCreate()
          }
        } else {
          this.toastDanger('Erros de validação', 'Preencha todos os campos obrigatórios')
        }
      })
    },

    addTeamUser(item) {
      this.$refs['modal-add-team-user'].show()
      this.teamUser.team_id = item.id
    },

    teamUserSave() {
      this.teamUser.user_id = this.accountUserSelected.value

      this.$refs.rulesTeamUser.validate().then(success => {
        if (success) {
          this.isSubmiting = true

          CrmService.postTeamUser(this.teamUser).then(response => {
            if (response.data.team) {
              this.toastSuccess('Sucesso!', 'Usuário adicionado ao time!')
              this.listTeams()
              this.$refs['modal-add-team-user'].hide()
            } else {
              this.swallError('Erro!', 'Não foi possível adicionar usuário ao time', 'Fechar')
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isSubmiting = false
          })
        } else {
          this.toastDanger('Erros de validação', 'Preencha todos os campos obrigatórios')
        }
      })
    },

    teamUserRemove(team, user) {
      this.$swal({
        title: 'Remover usuário do time?',
        text: `Deseja remover o usuário '${user.name}' do time?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.deleteTeamUser({ team_id: team.id, user_id: user.id }).then(response => {
            if (response.data.deleted) {
              this.listTeams()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    reset() {
      Object.entries(this.filtersValues).forEach(item => {
        this.filtersValues[item[0]] = ''
      })
      this.listTeams()
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.listTeams()
    },

    listUsers() {
      if (this.accountId !== 0) {
        this.isLoading = true

        this.filtersValues.page = this.currentPage
        CrmService.listUsers({ account_id: this.accountId }).then(response => {
          if (response.data !== null) {
            this.accountUsers = response.data.users.data
            this.pages = {
              ...response.data.users,
              data: null,
            }

            this.accountUsers = response.data.users.data.map(m => ({
              title: m.name,
              value: m.id,
            }))
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },

    listTeams() {
      if (this.accountId !== 0) {
        this.isLoading = true

        if (this.accountId) {
          this.filtersValues.account_id = this.accountId
        }

        this.filtersValues.page = this.currentPage
        CrmService.listTeams(this.filtersValues).then(response => {
          if (response.data !== null) {
            this.teams = response.data.teams.data
            this.pages = {
              ...response.data.teams,
              data: null,
            }
          }
        }).finally(() => {
          this.isLoading = false
          this.$refs.teams.showLoading = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
  #div-variations{
    padding-right: 160px;

    tbody tr,
    tbody tr td {
      background-color: transparent;

    }
    thead tr,
    thead tr th {
      background-color: transparent;
    }
  }
</style>
