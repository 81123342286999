<template>
  <span id="crm-user-list">
    <b-button
      v-if="product.c_prices && product.c_prices.length"
      v-b-modal="`modal-product-prices-${product.id}`"
      variant="flat-primary"
      size="sm"
      class="btn-icon ml-1"
      title="Ver preços"
    >
      <feather-icon
        icon="TrendingUpIcon"
        size="16"
        class="align-middle text-body"
      />
    </b-button>
    <b-modal
      :id="`modal-product-prices-${product.id}`"
      ok-only
      ok-title="Fechar"
    >
      <b-table
        :items="product.c_prices"
        :fields="fields"
        :striped="false"
        fixed
        small
        sort-icon-left
        no-local-sorting
      >
        <template #head(name)>
          Tabela
        </template>

        <template #head(price)>
          Preço
        </template>

        <template #cell(price)="row">
          <span>{{ row.item.price | currency }}</span>
        </template>
      </b-table>
    </b-modal>
  </span>
</template>

<script>
import {
  BButton,
  BTable,
} from 'bootstrap-vue'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'

export default {
  components: {
    BTable,
    BButton,
  },

  mixins: [toasts, swall],

  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      fields: [
        { key: 'name', sortable: false },
        { key: 'price', sortable: false },
      ],
    }
  },

  computed: {
  },

  watch: {
  },

  created() {
  },

  methods: {
  },
}
</script>
