var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"agendador"}},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-actions',{ref:"filters",attrs:{"title":"Editar Usuário","action-close":false,"action-refresh":false,"action-collapse":true},on:{"refresh":function($event){return _vm.refreshStop('cardAction')}}},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner')],1):_c('div',[_c('validation-observer',{ref:"rulesUser"},[_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"email","disabled":_vm.isSubmiting},on:{"blur":_vm.validateEmail},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),(_vm.isValidatingEmail)?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary","size":"sm"}},[_c('b-spinner',{attrs:{"small":""}})],1)],1):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Tipo de Pessoa","label-for":"person_type"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"person_type","options":_vm.crmPersonType,"disabled":_vm.isSubmiting},model:{value:(_vm.client.person_type),callback:function ($$v) {_vm.$set(_vm.client, "person_type", $$v)},expression:"client.person_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.client.person_type === 'pf')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"placeholder":"","options":_vm.maskCpf,"disabled":_vm.isSubmiting},model:{value:(_vm.client.cpf),callback:function ($$v) {_vm.$set(_vm.client, "cpf", $$v)},expression:"client.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2743811437)})],1)],1)],1):_vm._e(),(_vm.client.person_type === 'pj')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"cnpj"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"placeholder":"","options":_vm.maskCnpj,"disabled":_vm.isSubmiting},model:{value:(_vm.client.cnpj),callback:function ($$v) {_vm.$set(_vm.client, "cnpj", $$v)},expression:"client.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,274657197)})],1)],1)],1):_vm._e(),(_vm.client.person_type === 'pj')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"company_name"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.client.company_name),callback:function ($$v) {_vm.$set(_vm.client, "company_name", $$v)},expression:"client.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4020559471)})],1)],1)],1):_vm._e(),(_vm.client.person_type === 'pj')?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('b-form-group',{attrs:{"label":"Posição na empresa","label-for":"position_in_company"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.client.position_in_company),callback:function ($$v) {_vm.$set(_vm.client, "position_in_company", $$v)},expression:"client.position_in_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2666405097)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('user-edit-phones',{ref:"userEditPhones",attrs:{"phones":_vm.client.phones,"user-id":_vm.client.id,"is-submiting":_vm.isSubmiting},on:{"update:phones":function($event){return _vm.$set(_vm.client, "phones", $event)}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputCols}},[_c('user-edit-emails',{ref:"UserEditEmails",staticClass:"mt-2",attrs:{"emails":_vm.client.emails,"user-id":_vm.client.id,"is-submiting":_vm.isSubmiting},on:{"update:emails":function($event){return _vm.$set(_vm.client, "emails", $event)}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.inputColsAdrress}},[_c('user-edit-addresses',{ref:"UserEditAddresses",staticClass:"mt-2",attrs:{"addresses":_vm.client.addresses,"user-id":_vm.client.id,"is-submiting":_vm.isSubmiting},on:{"update:addresses":function($event){return _vm.$set(_vm.client, "addresses", $event)}}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"ml-2 text-right",attrs:{"disabled":_vm.isSubmiting},on:{"click":_vm.back}},[_vm._v(" Fechar ")]),_c('b-button',{staticClass:"ml-2 text-right",attrs:{"disabled":_vm.isSubmiting},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }