<template xmlns:b-row="http://www.w3.org/1999/html">
  <section id="agendador">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Editar Conta"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <div
            v-if="isLoading"
            class="text-center"
          >
            <b-spinner />
          </div>
          <div v-else>
            <validation-observer ref="rulesAccount">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="account.name"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Plano"
                    label-for="active_plan_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-select
                        id="active_plan_type"
                        v-model="account.active_plan_type"
                        :options="crmActivePlanTypes"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-button
                    class="ml-2 text-right"
                    :disabled="isSubmiting"
                    :to="{ name: 'crm-account-list' }"
                  >
                    Voltar
                  </b-button>

                  <b-button
                    class="ml-2 text-right"
                    :disabled="isSubmiting"
                    @click="saveAccount"
                  >
                    Salvar
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-card-actions>

        <client-list
          v-if="this.$route.params.id"
          :account-id="parseInt($route.params.id, 10)"
          :show-filters="false"
          :show-internal-filters="true"
          :edit-in-modal="true"
          type="client"
          title="Clientes da conta"
          :collapsed="true"
        />

        <user-list
          v-if="this.$route.params.id"
          :account-id="parseInt($route.params.id, 10)"
          :show-filters="false"
          :show-internal-filters="true"
          :edit-in-modal="true"
          type="user"
          title="Usuários da conta"
          :collapsed="true"
        />

        <product-list
          v-if="this.$route.params.id"
          :account-id="parseInt($route.params.id, 10)"
          :show-filters="false"
          :show-internal-filters="true"
          :edit-in-modal="true"
          title="Produtos da conta"
          :collapsed="true"
        />

        <team-list
          v-if="this.$route.params.id"
          :account-id="parseInt($route.params.id, 10)"
          :show-filters="false"
          title="Times da conta"
          :collapsed="true"
        />

        <negotiation-list
          v-if="this.$route.params.id"
          :account-id="parseInt($route.params.id, 10)"
          :show-filters="false"
          title="Negócios da conta"
          :collapsed="true"
        />

      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BSpinner,
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import CrmService from '@/services/crmService'
import UserList from '@/views/crm/user/UserList.vue'
import ClientList from '@/views/crm/client/ClientList.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import requestErrorMessage from '@/mixins/requestErrorMessage'
import { crmActivePlanTypes } from '@/utils/options'
import ProductList from '@/views/crm/product/ProductList.vue'
import TeamList from '@/views/crm/team/TeamList.vue'
import NegotiationList from '@/views/crm/negotiation/NegotiationList.vue'

export default {
  components: {
    NegotiationList,
    ProductList,
    TeamList,
    UserList,
    ClientList,
    BSpinner,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormSelect,
    BButton,
    BCardActions,
    BRow,
    BCol,
    BBreadcrumb,
  },
  mixins: [toasts, swall, requestErrorMessage],

  props: {
    managerIdFromProps: {
      type: Number,
      default: null,
    },
    paymentDateFrom: {
      type: String,
      default: null,
    },
    paymentDateTo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Contas',
          to: { name: 'crm-account-list' },
        },
        {
          text: 'Editar Conta',
          active: true,
        },
      ],
      crmActivePlanTypes,
      isSubmiting: false,
      isLoading: false,
      isRemoving: false,
      account: {
        id: 0,
        name: null,
        active_plan_type: null,
      },
      userResultFields: [
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'person_type', sortable: false },
        { key: 'permission_type', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      results: [],
      userData: null,
    }
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.getAccount()
  },

  methods: {
    saveAccount() {
      return this.$refs.rulesAccount.validate().then(success => {
        if (success) {
          this.isSubmiting = true

          if (this.account.id === 0) {
            CrmService.postAccount(this.account).then(response => {
              if (response.data.account) {
                this.toastSuccess('Sucesso!', 'Conta criada!')
              } else {
                this.swallError('Erro!', 'Não foi possível criar a conta', 'Fechar')
              }
            }).catch(error => {
              this.swallError('Erro!', error.response.data.message, 'Fechar')
            }).finally(() => {
              this.isSubmiting = false
            })
          } else {
            CrmService.putAccount(this.account).then(response => {
              if (response.data.account) {
                this.toastSuccess('Sucesso!', 'Conta alterada!')
              } else {
                this.swallError('Erro!', 'Não foi possível salvar a conta', 'Fechar')
              }
            }).catch(error => {
              this.swallError('Erro!', error.response.data.message, 'Fechar')
            }).finally(() => {
              this.isSubmiting = false
            })
          }
        } else {
          this.toastDanger('Erros de validação', 'Preencha todos os campos obrigatórios')
        }
      })
    },

    getAccount() {
      if (this.$route.params.id !== 0) {
        this.isLoading = true
        CrmService.showAccount(this.$route.params.id).then(response => {
          if (response.data !== null) {
            this.account = response.data.account
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
  },
}
</script>
