<template>
  <section id="crm-user-list">
    <b-breadcrumb
      v-if="showFilters"
      :items="breadcrumb"
    />

    <b-card-actions
      v-if="showFilters"
      ref="filters"
      :title-collapse="true"
      title="Filtros"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      :collapsed="false"
      @refresh="listNegotiations"
    >
      <negotiation-list-filters
        :filters.sync="filtersValues"
        :is-loading="isLoading"
        @search="listNegotiations"
      />
    </b-card-actions>

    <b-card-actions
      ref="negotiations"
      :title="pageTitle"
      :title-collapse="true"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="true"
      :collapsed="collapsed"
      @refresh="listNegotiations"
    >
      <b-row>
        <b-col>
          <negotiation-list-filters
            :filters.sync="filtersValues"
            :is-loading="isLoading"
            @search="listNegotiations"
          />

          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :busy="isLoading || isRemoving"
            :items="negotiations"
            :fields="resultFields"
            striped
            sort-icon-left
            no-local-sorting
          >
            <template #head(name)>
              Nome
            </template>

            <template #head(amount)>
              Valor total
            </template>

            <template #head(qtd_products)>
              Produtos
            </template>

            <template #head(actions)>
              <div class="text-center">
                <span>Ações</span>
              </div>
            </template>

            <template #cell(status)="row">
              <span>{{ $t(`crm.negotiation.status.${row.item.status}`) }}</span><br>

            </template>

            <template #cell(amount)="row">
              <span>{{ row.item.amount | currency }}</span><br>
            </template>

            <template #cell(qtd_products)="row">
              <span>{{ row.item.products.length }}</span><br>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  :disabled="isLoading || isRemoving"
                  title="Ver"
                  @click="showNegotiation(row.item)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page"
            :link-gen="() => { return '#' }"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal
      ref="modal-negotiation-show"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="xl"
    >
      <b-row>
        <b-col cols="12">
          <h1>
            {{ negotiation.name }}
          </h1>
          <hr />

          <b-row>
            <b-col cols="3">
              <label class="d-block">Status</label>
              <span>{{ $t(`crm.negotiation.status.${negotiation.status}`) }}</span>
            </b-col>

            <b-col cols="3">
              <label class="d-block">Valor</label>
              <span>{{ negotiation.amount | currency }}</span>
            </b-col>

            <b-col cols="3">
              <label class="d-block">Produtos</label>
              <span>{{ negotiation.products.length }}</span>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="3">
              <label class="d-block">Cliente</label>
              <span>{{ negotiation.client.name }}</span><br>
              <span>{{ negotiation.client.email }}</span><br>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                v-if="negotiationProducts.length"
                class="mt-2"
                :busy="isLoading || isRemoving"
                :items="negotiationProducts"
                :fields="negotiationProductsFields"
                fixed
                striped
                sort-icon-left
                no-local-sorting
              >
                <template #head(name)>
                  Nome
                </template>

                <template #head(unit_quantity)>
                  Quantidade
                </template>

                <template #head(type)>
                  Tipo
                </template>

                <template #head(price)>
                  Preço
                </template>

                <template #head(amount)>
                  Total
                </template>

                <template #cell(type)="row">
                  <span v-if="row.item.id">
                    {{ $t(`crm.product.type.${row.item.type}`) }}
                  </span>
                </template>

                <template #cell(unit_quantity)="row">
                  <span v-if="row.item.id">
                    {{ row.item.unit_quantity }} ({{ $t(`crm.product.unit.${row.item.unit}`) }})
                  </span>

                </template>

                <template #cell(price)="row">
                  <span v-if="row.item.id">
                    {{ row.item.price | currency }}
                  </span>
                </template>

                <template #cell(amount)="row">
                  {{ row.item.amount | currency }}
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BPaginationNav,
  BRow,
  BTable,
} from 'bootstrap-vue'
import CrmService from '@/services/crmService'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { crmAccountUserPermissionTypes } from '@/utils/options'
import { productsType, negotiationStatus } from '@/utils/crmConstants'
import NegotiationListFilters from '@/views/crm/negotiation/NegotiationListFilters.vue'

export default {
  components: {
    NegotiationListFilters,
    BCardActions,
    BPaginationNav,
    BButton,
    BTable,
    BRow,
    BCol,
    BBreadcrumb,
  },

  mixins: [toasts, swall],

  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    accountId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Usuário',
          to: { name: 'crm-user-list' },
        },
      ],
      filtersValues: {
        name: '',
        product_name: '',
        status: '',
        page: 1,
      },
      userIdToEdit: null,
      pageTitle: '',
      pages: {},
      currentPage: 1,
      negotiations: [],
      negotiation: {
        name: '',
        status: '',
        amount: '',
        account: {},
        products: [],
        client: {},
      },
      negotiationProductsFields: [
        { key: 'name', sortable: false },
        { key: 'unit', sortable: false },
        { key: 'price', sortable: false },
        { key: 'unit_quantity', sortable: false },
        { key: 'type', sortable: false },
        { key: 'amount', sortable: false },
      ],
      resultFields: [
        { key: 'name', sortable: false },
        { key: 'qtd_products', sortable: false },
        { key: 'amount', sortable: false },
        { key: 'status', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isRemoving: false,
      isSubmiting: false,
      crmAccountUserPermissionTypes,
      productsType,
      negotiationStatus,
    }
  },

  computed: {
    negotiationProducts() {
      if (this.negotiation.products.length === 0) {
        return []
      }

      let amount = 0
      const p = this.negotiation.products.map(m => {
        amount = parseFloat(amount)
        amount += m.price * m.unit_quantity
        amount = parseFloat(amount.toFixed(2))

        return {
          ...m,
          amount,
        }
      })
      p.push(
        {
          amount: p[p.length - 1].amount,
        },
      )

      return p
    },

    entityName() {
      return this.type === 'client' ? 'cliente' : 'usuário'
    },
  },

  watch: {
    currentPage(page) {
      this.currentPage = page
      this.listNegotiations()
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.pageTitle = this.title ?? 'Produtos'

    this.listNegotiations()
  },

  methods: {
    reset() {
      Object.entries(this.filtersValues).forEach(item => {
        this.filtersValues[item[0]] = ''
      })
      this.listNegotiations()
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.listNegotiations()
    },

    showNegotiation(item) {
      this.negotiation = item
      this.$refs['modal-negotiation-show'].show()
    },

    listNegotiations() {
      if (this.accountId !== 0) {
        this.isLoading = true

        if (this.accountId) {
          this.filtersValues.account_id = this.accountId
        }

        this.filtersValues.page = this.currentPage
        this.filtersValues.append = ['client']
        CrmService.listNegotiations(this.filtersValues).then(response => {
          if (response.data !== null) {
            this.negotiations = response.data.negotiations.data
            this.pages = {
              ...response.data.negotiations,
              data: null,
            }
          }
        }).finally(() => {
          this.isLoading = false
          this.$refs.negotiations.showLoading = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
  #div-variations{
    padding-right: 160px;

    tbody tr,
    tbody tr td {
      background-color: transparent;

    }
    thead tr,
    thead tr th {
      background-color: transparent;
    }
  }
</style>
